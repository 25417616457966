
import { namespace } from 'vuex-class';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { UiMixin } from '@/mixins/ui-mixin';

const ui = namespace('ui');
const auth = namespace('auth');

@Component({})
export default class Login extends Mixins<UiMixin>(UiMixin) {
  @auth.Mutation
  setUser!: (userReq: any) => void;

  login = {
    email: '',
    password: '',
  };

  async loginUser() {
    this.setLoader(true);
    try {
      const req = await this.$http.post('/auth/login', { ...this.login });
      this.setUser(req.data);
      this.$httpSetBearer();
      await this.$router.push({ path: '/admin' });
    } catch (e) {
      this.alert('Usuario o contraseña incorrecta', 'error');
    }
    this.setLoader(false);
  }
}
